import React, { Component } from 'react';
import { Select, MenuItem, Button, InputLabel, FormControl, FormControlLabel, Switch, Input, Checkbox, ListItemText, Chip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'recompose';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import './ManageAPI.css';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class EditAPI extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = ({
            selectValue: "Window",
            server: "",
            username: "",
            password: "",
            description: "",
            db: [],
            dbSelected: "",
            dbIsLoaded: false,
            table: [],
            tableIsLoaded: false,
            tableSelected: "",
            column: [],
            columnSelected: [],
            columnIsLoaded: false,
            tableRelated: [],
            tableRelatedSelected: "",
            tableRelatedIsLoaded: false,
            columnRelated: [],
            columnRelatedSelected: [],
            columnRelatedSelectedIsLoaded: false,
            dialogOpen: false,
            storedApi: "",
            description: this.props.row.original.description,
            storedApi: "",
            columnSelectAll: false,
            columnRelatedSelectedAll: false,
            changeConnString: false,
            usePassword: false,
            connection: [],
            connectionLoaded: false,
            connId: this.props.row.original.connId
        });
        this.GenerateAPI = this.GenerateAPI.bind(this);
        this.GetTable = this.GetTable.bind(this);
        this.GetColumnNewConn = this.GetColumnNewConn.bind(this);
        this.GetRelatedTable = this.GetRelatedTable.bind(this);
    }

    componentDidMount() {

        fetch('/api/APIManagement/GetColumn', {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'guid': this.props.row.original.guid,
                'TableName': this.props.row.original.tableName

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }
        }).then(data => {
            let colTrim = [];
            let colSelected = this.props.row.original.columnName.split(',');
            colSelected.map(c => {
                let col = c.trim();
                colTrim.push(col);
            })
            this.setState({ column: data, columnIsLoaded: true, columnSelected: colTrim });
        });

        let tableName = this.props.row.original.tableName.substring(this.props.row.original.tableName.indexOf(".") + 1, this.props.row.original.tableName.indexOf("("));
        let schemaName = this.props.row.original.tableName.substring(0, this.props.row.original.tableName.indexOf("."));

        fetch('/api/APIManagement/GetRelatedTable', {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'guid': this.props.row.original.guid,
                'TableName': tableName,
                'schemaName': schemaName

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }
        }).then(data => this.setState({ tableRelatedSelected: this.props.row.original.relatedTable, tableRelated: data, tableRelatedIsLoaded: true }));

        if (this.props.row.original.relatedTable != "") {
            fetch('/api/APIManagement/GetColumn', {
                method: 'POST',
                credentials: 'same-origin',              
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'guid': this.props.row.original.guid,
                    'TableName': this.props.row.original.relatedTable
    
                })
            }).then(response => {
                if (response.status === 401) {
                    this.setState({ redirectToReferrer: true });
                }
                else {
                    return response.json();
                }

            }).then(data => {
                let colTrim = [];
                let colSelected = this.props.row.original.relatedColumn.split(',');
                colSelected.map(c => {
                    let col = c.trim();
                    colTrim.push(col);
                })
                this.setState({ columnRelated: data, columnRelatedSelectedIsLoaded: true, columnRelatedSelected: colTrim });
            });
        }
    }

    GenerateAPI() {
        let colName = this.state.columnSelected.join(", ");
        let relColumn = this.state.columnRelatedSelected.join(", ");

        fetch('/api/APIManagement', {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "guid": this.props.row.original.guid,
                "colName": colName,
                "relatedTable": this.state.tableRelatedSelected,
                "relColumn": relColumn,
                "description": this.state.description,
                "connStringGuid": this.state.connId,
                "TableName": this.state.tableSelected,
                "ConnId" : this.state.connId
            })
        }).then(response => {
            this.setState({ dialogOpen: true, storedApi: this.props.row.original.guid });

            let storedApi = this.props.row.original;
            storedApi["description"] = this.state.description;
            storedApi["relColumn"] = relColumn;
            storedApi["relatedTable"] = this.state.tableRelatedSelected;
            storedApi["colName"] = colName;

            this.props.changeState(this.props.row.index, storedApi);
        });
    }

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    selectOnchange = name => event => {
        this.setState({ [name]: event.target.value });
        if (name == "tableSelected")
            this.GetColumnNewConn(event.target.value, false);
        if (name == "tableRelatedSelected") {
            this.GetColumnNewConn(event.target.value, true);           
        }
        if (name == "connSelected") {
            this.GetTable(event.target.value);
            this.setState({ connId: event.target.value, connectionSaved: true });
        }

    };

    GetColumnNewConn(table, isRelatedTable) {
      fetch('/api/GenerateApi/GetColumn', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": this.state.connId,
                "tableName": table,

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }
        }).then(data => {
            if (!isRelatedTable) {
                this.setState({ column: data, columnSelected: [], columnIsLoaded: true });
                let tableName = table.substring(table.indexOf(".") + 1, table.indexOf("("));
                let schemaName = table.substring(0, table.indexOf("."));
                this.GetRelatedTable(tableName, schemaName)
            }
            else {
                this.setState({ columnRelated: data, columnRelatedSelected: [], columnRelatedSelectedIsLoaded: true });
            }
        });
    }

    textfieldOnChange = name => event => {
        this.setState({ [name]: event.target.value });
    };
    switchOnChange = name => event => {
        if (event.target.checked == true) {
            this.GetSavedConnection();
        }
        else {
            this.setState({ connectionLoaded: false });
        }
        this.setState({ [name]: event.target.checked });
    };
    handleClose = () => {
        this.setState({ dialogOpen: false });
    };
    multipleOnChange = name => event => {
        if (name == "columnRelatedSelected") {
            if (event.target.value.indexOf("SELECT ALL") > -1) {
                this.setState({ [name]: this.state.columnRelated, columnRelatedSelectedAll: true })
            }
            else if (event.target.value.indexOf("SELECT NONE") > -1) {
                this.setState({ [name]: [], columnRelatedSelectedAll: false })
            }
            else {
                let currentSelectedColumn = this.state.columnRelatedSelected;
                let columnEventSelected = event.target.value;

                if (columnEventSelected.length > currentSelectedColumn.length && columnEventSelected.length != currentSelectedColumn.length) {
                    let selected = event.target.value[event.target.value.length - 1];
                    let compare = "";
                    if (selected != undefined) {
                        compare = selected.toString().substring(0, selected.indexOf("("));
                        var duplicateColumn = columnEventSelected.filter(word => word.includes(compare));
                        if (duplicateColumn.length > 1) {
                            var currentColumn = columnEventSelected.find(a => a.includes(compare));
                            //See if there a duplicate column   
                            if (currentColumn != null && currentColumn != undefined) {
                                let currentColumnIndex = columnEventSelected.indexOf(currentColumn);
                                columnEventSelected.splice(currentColumnIndex, 1);
                                //Check if its duplicate or not
                                currentColumn = columnEventSelected.find(a => a.includes(compare));
                                if (currentColumn != undefined) {
                                    currentColumnIndex = columnEventSelected.indexOf(currentColumn);
                                    if (currentColumnIndex < 0) {
                                        columnEventSelected.push(currentColumn);
                                    }
                                }
                            }
                        }
                    }
                }
                this.setState({ [name]: columnEventSelected })
            }

        }
        else {
            if (event.target.value.indexOf("SELECT ALL") > -1) {
                this.setState({ [name]: this.state.column, columnSelectAll: true })
            }
            else if (event.target.value.indexOf("SELECT NONE") > -1) {
                this.setState({ [name]: [], columnSelectAll: false })
            }
            else {
                let currentSelectedColumn = this.state.columnSelected;
                let columnEventSelected = event.target.value;

                if (columnEventSelected.length > currentSelectedColumn.length && columnEventSelected.length != currentSelectedColumn.length) {
                    let selected = event.target.value[event.target.value.length - 1];
                    let compare = "";
                    if (selected != undefined) {
                        compare = selected.toString().substring(0, selected.indexOf("("));
                        var duplicateColumn = columnEventSelected.filter(word => word.includes(compare));
                        if (duplicateColumn.length > 1) {
                            var currentColumn = columnEventSelected.find(a => a.includes(compare));
                            //See if there a duplicate column   
                            if (currentColumn != null && currentColumn != undefined) {
                                let currentColumnIndex = columnEventSelected.indexOf(currentColumn);
                                columnEventSelected.splice(currentColumnIndex, 1);
                                //Check if its duplicate or not
                                currentColumn = columnEventSelected.find(a => a.includes(compare));
                                if (currentColumn != undefined) {
                                    currentColumnIndex = columnEventSelected.indexOf(currentColumn);
                                    if (currentColumnIndex < 0) {
                                        columnEventSelected.push(currentColumn);
                                    }
                                }
                            }
                        }
                    }
                }
                this.setState({ [name]: columnEventSelected })
            }

        }


    };

    GetSavedConnection() {
        fetch('/api/ApiManagement/GetStoredConn', {
            method: 'GET',
            credentials: 'same-origin'
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => this.setState({ connection: data, connectionLoaded: true }));
    }

    checkboxChecker = column => {
        let colSelected = [];
        this.state.columnSelected.map(c => {
            let colTrim = c.trim();
            colSelected.push(colTrim);
        })
        if (colSelected.indexOf(column) > -1) {
            return true;
        }
        else {
            return false;
        }
    }

    checkboxCheckerRelatedColumn = column => {
        let colSelected = [];
        this.state.columnRelatedSelected.map(c => {
            let colTrim = c.trim();
            colSelected.push(colTrim);
        })
        if (colSelected.indexOf(column) > -1) {
            return true;
        }
        else {
            return false;
        }
    }

    GetTable(Id) {
        fetch('/api/GenerateApi/GetTable', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": Id

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => this.setState({ table: data, tableIsLoaded: true }));
    }

    GetRelatedTable(table, schemaName) {
      fetch("/api/generateapi/GetReferenceTable", {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": this.state.connId,
                "tableName": table,
                "schemaName": schemaName

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data =>

            this.setState({ tableRelated: data })
        );
    }

    render() {
        return (
            <div>
                <div  >
                    {this.state.columnIsLoaded ?
                        <div className="Body" >
                            <h4>{this.props.row.original.tableName}</h4>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.changeConnString}
                                        onChange={this.switchOnChange("changeConnString")}
                                    />
                                }
                                label="Change Connection"
                            />
                            {this.state.changeConnString ? <div className="Body" >
                                {this.state.connectionLoaded ?
                                    <FormControl className="ComponentDiv" margin="normal" >
                                        <InputLabel htmlFor="conn-selected">Saved Connection</InputLabel>
                                        <Select
                                            value={this.state.connId}
                                            onChange={this.selectOnchange('connSelected')}
                                            inputProps={{
                                                name: 'conn',
                                                id: 'conn-selected',
                                            }}
                                        >
                                            {this.state.connection.map(item => (
                                                <MenuItem value={item.id}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> : <div />}
                            </div> : <div> </div>}
                            {this.state.tableIsLoaded ? <FormControl margin="normal"  >
                                <InputLabel htmlFor="table-selected">Table</InputLabel>
                                <Select
                                    className="TableSelect"
                                    value={this.state.tableSelected}
                                    onChange={this.selectOnchange('tableSelected')}
                                    inputProps={{
                                        name: 'db',
                                        id: 'table-selected',
                                    }}
                                >
                                    {this.state.table.map(item => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                : <div></div>}
                            <FormControl className="ComponentDiv" margin="normal" >
                                <InputLabel htmlFor="select-multiple-checkbox"> Column</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.columnSelected}
                                    onChange={this.multipleOnChange("columnSelected")}
                                    input={<Input id="select-multiple-checkbox" />}
                                    renderValue={selected => (
                                        <div className="row">

                                            {selected.map(value => (
                                                <div className="column" >
                                                    <Chip key={value} label={value} className="chip" />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                >
                                    <MenuItem key="SELECT ALL" value={this.state.columnSelectAll ? "SELECT NONE" : "SELECT ALL"} >
                                        <ListItemText primary={this.state.columnSelectAll ? "SELECT NONE" : "SELECT ALL"} />
                                    </MenuItem>
                                    {this.state.column.map(column => (
                                        <MenuItem key={column} value={column}>
                                            <Checkbox checked={this.state.columnSelected.indexOf(column) > -1} />
                                            <ListItemText primary={column} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {this.state.tableRelatedIsLoaded ? <div className="Body" > <FormControl margin="normal" >
                                <InputLabel htmlFor="table-selected">Related Table</InputLabel>
                                <Select
                                    className="ComponentDiv"
                                    value={this.state.tableRelatedSelected}
                                    onChange={this.selectOnchange('tableRelatedSelected')}
                                    inputProps={{
                                        name: 'db',
                                        id: 'tableRelated-selected',
                                    }}
                                >
                                    {this.state.tableRelated.map(item => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                                {this.state.columnRelatedSelectedIsLoaded ?
                                    <FormControl className="ComponentDiv" margin="normal" >
                                        <InputLabel htmlFor="select-multiple-checkbox">Related Column</InputLabel>
                                        <Select
                                            multiple
                                            value={this.state.columnRelatedSelected}
                                            onChange={this.multipleOnChange("columnRelatedSelected")}
                                            input={<Input id="select-multiple-checkbox" />}
                                            renderValue={selected => (
                                                <div className="row">

                                                    {selected.map(value => (
                                                        <div className="column" >
                                                            <Chip key={value} label={value} className="chip" />
                                                        </div>
                                                    ))}


                                                </div>
                                            )}
                                        >
                                            <MenuItem key="SELECT ALL" value={this.state.columnRelatedSelectedAll ? "SELECT NONE" : "SELECT ALL"} >
                                                <ListItemText primary={this.state.columnRelatedSelectedAll ? "SELECT NONE" : "SELECT ALL"} />
                                            </MenuItem>
                                            {this.state.columnRelated.map(column => (
                                                <MenuItem key={column} value={column}>
                                                    <Checkbox checked={this.state.columnRelatedSelected.indexOf(column) > -1} />
                                                    <ListItemText primary={column} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    : <div></div>}
                            </div>
                                : <div></div>}
                            <TextField
                                className="ComponentDiv"
                                multiline
                                value={this.state.description}
                                onChange={this.textfieldOnChange("description")}
                                label="Description"
                                margin="normal"
                                variant="outlined"
                            />
                            <Button className="ComponentDiv" onClick={this.GenerateAPI} variant="outlined"  > Save </Button>
                        </div>
                        : <div />}
                    <Dialog
                        open={this.state.dialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Edit API Succeed"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {"Use this Endpoint to access your API " + this.state.storedApi}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Okay
                    </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div >
        );
    }
}

export default compose(withRouter, withCookies)(EditAPI)
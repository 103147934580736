import React, { Component } from 'react';
import { BrowserRouter, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Counter } from './components/Counter';
import LoginPage from './components/Logins/LoginPage';
import TransactionLog from './components/TransactionLog/TransactionLog';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import TransactionLogDetail from './components/TransactionLog/TransactionLogDetail';
import ManageAPI from './components/ApiManagement/ManageAPI';
import DaatabaseSelector from './components/ApiGenerator/DaatabaseSelector';
import UserManagement from './components/UserManagement/UserManagement';
import UploadData from './components/UploadData/UploadData';
import LogUploadData from './components/UploadData/LogUploadData';

const response = fetch('/api/User/CheckToken', {
  method: "GET",
  credentials: "same-origin"
}).then(resp => resp.status).then(status => {
  if (status === 200)
    return true
  else
    return false
});



const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route {...rest} render={(props) => (
    // cookies.get('token')  === "" || cookies.get('token') === undefined
    response
      ? <Component {...props} /> :
      <Redirect to={{
        pathname: '/loginpage',
        state: { from: props.location }
      }} />

  )} />
)


class App extends Component {

  static displayName = App.name;
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }
  constructor(props) {
    super(props);
    this.state = ({
      isLogin: false
    })
  }



  CheckToken() {

    fetch('/api/User/CheckToken', {
      method: "GET",
      credentials: "same-origin"
    }).then(response => {
      if (response.status === 200) {
        this.setState({ isLogin: true })
      }

      else {
        this.setState({ isLogin: false })
      }

    });

  }

  render() {
    const { cookies } = this.props;
    return (
      <Layout>
        <PrivateRoute exact path='/' component={DaatabaseSelector} />
        <PrivateRoute path='/counter' component={Counter} />
        <Route path='/LoginPage' component={LoginPage} />
        <PrivateRoute path='/ManageApi' component={ManageAPI} />
        <PrivateRoute path='/LogUploadData' component={LogUploadData} />
        <PrivateRoute path='/logTransaction' component={TransactionLog} />
        <PrivateRoute path='/logDetails' component={TransactionLogDetail} />
        <PrivateRoute path='/userManagement' component={UserManagement} />
        <PrivateRoute path='/UploadData' component={UploadData} />
      </Layout>
    );
  }
}
export default withCookies(App)

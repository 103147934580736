import React, { Component } from 'react';
import ReactTable from 'react-table';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';

function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined && row[id] !== null ?
            String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            :
            true
    );
}

class LogUploadData extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            "LogUploadData": [],
            "redirectToReferrer": false,
        })
    }

    componentWillMount() {
        fetch("/api/UploadData", {
            method: "GET",
            credentials: 'same-origin'
        }).then(response => {
            if (response.status === 200) {
                return response.json()
            }
            else {
                this.setState({ "redirectToReferrer": true })
            }
        }).then(data => this.setState({ "LogUploadData": data }));
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/LoginPage' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div>
                <ReactTable
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    defaultPageSize={10}
                    data={this.state.LogUploadData}
                    columns={[
                        {
                            Header: "Uploader",
                            accessor: "uploader",
                            width: 340
                        },
                        {
                            Header: "Created Date",
                            accessor: "createdDateTime",

                        },
                        {
                            Header: "Category",
                            accessor: "kategori"
                        },
                        {
                            Header: "File Name",
                            accessor: "fileName"
                        },
                        {
                            Header: "Path",
                            accessor: "path"
                        }
                    ]}
                    defaultSorted={[
                        {
                            id: "createdDateTime",
                            desc: true
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withRouter(LogUploadData);
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import './LoginPage.css';
import { Button } from '@material-ui/core';
import {instanceOf} from 'prop-types';
import {withCookies, Cookies} from 'react-cookie';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import {compose} from 'recompose';

class LoginPage extends Component {
    static propTypes = {
        cookies : instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = ({
         email : "",
         password : "",
         redirectToReferrer: false,
        });
    }

    textFieldOnChange = name => event => {
        this.setState({
        [name] : event.target.value
        });
    }

    login(){
     const {cookies} = this.props;
     fetch("/api/user/login",{
         method : "POST",
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
         body : JSON.stringify({
         "Email" : this.state.email,
         "Password" : this.state.password
         })
     }).then(response => response.json()).then(data => { 
        // var tomorrow = new Date();
        // tomorrow.setDate(tomorrow.getDate()+1);
        
        // cookies.set('token', data.data.token, {path : '/', expires : tomorrow, httpOnly : true});
        this.setState(() => ({
            redirectToReferrer: true
          }))
        });
    }
 
    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
          }
        return (
            <div>

                <form className="LoginPage"  >
                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="Email"
                        onChange={this.textFieldOnChange("email")}
                    />
                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="Password"
                        type="password"
                        onChange={this.textFieldOnChange("password")}
                    />
                    <div className="LoginButtonDiv">
                        <Button
                            variant="contained"
                            className="ButtonPage"
                            color="primary"
                            onClick={this.login.bind(this)}
                        > Login </Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default compose(withCookies, withRouter) (LoginPage);
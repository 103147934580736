import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'recompose';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import './TransactionLogDetail.css';

class TransactionLogDetail extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = ({
            logDetail: [],
            redirectToReferrer: false,
            selected: null,
            rowSelectedInfo: []
        });

        fetch("/api/logtransaction/" + this.props.location.state.detail.guid, {
            method: "GET",
            credentials: 'same-origin'
            // headers: new Headers({
            //     'Authorization': "Bearer " + cookies.get("token")
            // })
        }).then(response => {
            if (response.status === 401) {
                cookies.remove("token", { path: '/' });
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => this.setState({
            logDetail: data
        }));

    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/loginpage' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div  >
                <div className="Body" >
                    {this.state.rowSelectedInfo.length == 0  ? <div>
                        </div>
                        :  <div> <h5>API GUID    : {this.state.rowSelectedInfo.storedApiGuidName}</h5>
                        <h5>INFO : {this.state.rowSelectedInfo.info}</h5>
                        <h5>ERROR DETAIL  : {this.state.rowSelectedInfo.error} </h5>
                        <h5>TIMESTAMP    : {this.state.rowSelectedInfo.timestamp}</h5>
                    </div>}

                </div>

                <ReactTable
                    defaultPageSize={10}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    this.setState({
                                        selected: rowInfo.index,
                                        rowSelectedInfo: rowInfo.original
                                    })
                                },
                                style: {
                                    background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                                }
                            }
                        } else {
                            return {}
                        }
                    }}
                    data={this.state.logDetail}
                    columns={[
                        {
                            Header: "API Guid",
                            accessor: "storedApiGuidName"
                        },
                        {
                            Header: "Info",
                            accessor: "info"
                        },
                        {
                            Header: "Error",
                            accessor: "error"
                        },
                        {
                            Header: "Timestamp",
                            accessor: "timestamp",

                        }

                    ]}
                />

            </div>
        );
    }
}

export default compose(withCookies, withRouter)(TransactionLogDetail);
import React, { Component } from 'react';
import './UserManagement.css';
import { TextField, Button } from '@material-ui/core';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'recompose';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class UserManagement extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = ({
            CreateMessage: "",
            FirstName: "",
            LastName: "",
            Email: "",
            Password: "",
            dialogOpen: false
        })
    }

    RegisterNewUser() {
        const { cookies } = this.props;
        fetch('/api/user', {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                //'Authorization': "Bearer " + cookies.get("token"),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "FirstName": this.state.FirstName,
                "LastName": this.state.LastName,
                "Email": this.state.Email,
                "Password": this.state.Password
            })
        }).then(response => {
            if (response.status === 200) {
                this.setState({ dialogOpen: true, CreateMessage: "New user is Created" });
            }
            else {
                this.setState({ dialogOpen: true, CreateMessage: "User already exist" });
            }
        }
        );
    }
    handleClose = () => {
        this.setState({ dialogOpen: false });
    };
    textFieldOnChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    }
    render() {
        return (
            <div>
                <form className="LoginPage"  >

                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="FirstName"
                        onChange={this.textFieldOnChange("FirstName")}
                    />
                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="LastName"
                        onChange={this.textFieldOnChange("LastName")}
                    />
                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="Email"
                        onChange={this.textFieldOnChange("Email")}
                    />
                    <TextField
                        margin="normal"
                        className="TextFieldStyle"
                        label="Password"
                        type="password"
                        onChange={this.textFieldOnChange("Password")}
                    />
                    <div className="LoginButtonDiv">
                        <Button
                            variant="contained"
                            className="ButtonPage"
                            color="primary"
                            onClick={this.RegisterNewUser.bind(this)}
                        > Register User </Button>
                    </div>
                </form>
                <Dialog
                    open={this.state.dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        Create User
                        </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.state.CreateMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={this.handleClose} color="primary">
                            Okay
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(withCookies, withRouter)(UserManagement);
import React, { Component } from 'react';
import { Select, MenuItem, Button, InputLabel, FormControl, FormControlLabel, Switch, Input, Checkbox, ListItemText } from '@material-ui/core';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import './ManageAPI.css';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'recompose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import EditAPI from './EditAPI';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return (
      row[id] !== undefined ?
          String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      :
          true
  );
}

class ManageAPI extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { storedApi: [], row: [], dialogOpen: false, redirectToReferrer: false, deleteOpen : false };

    fetch('/api/APIManagement', {
      method: "GET",
      credentials: 'same-origin'
      // headers: new Headers({
      //   'Authorization': "Bearer " + cookies.get("token")
      // })
    }).then(response => {
      if (response.status === 401) {
        fetch('/api/user/LogOut');
       // cookies.remove("token", { path: '/' });
        this.setState({ redirectToReferrer: true });
      }
      else {
        return response.json();
      }

    }).then(data => this.setState({ storedApi: data }));
    this.changeState = this.changeState.bind(this);
  }
  changeState(index , storedApi){
  let listStoredAPI = this.state.storedApi;
  listStoredAPI.splice(index, 1);
  listStoredAPI.push(storedApi);
  this.setState({storedAPi : listStoredAPI});
  }
  deleteStoredAPI() {
    let guid = this.state.row.original.guid;
    const { cookies } = this.props;
    fetch('/api/APIManagement/' + guid, {
      method: "DELETE",
      credentials: 'same-origin'
      // headers: new Headers({
      //   'Authorization': "Bearer " + cookies.get("token")
      // })
    }).then(response => {
      if (response.status === 401) {
        cookies.remove("token", { path: '/' });
        this.setState({ redirectToReferrer: true });
      }
      else{
        let storedAPi = this.state.storedApi;
        storedAPi.splice(this.state.row.index, 1);
        this.setState({deleteOpen : false, storedAPi : storedAPi});
      }


    });
  }

  handleClose = () => {
    this.setState({ dialogOpen: false, deleteOpen : false });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/loginpage' } }
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />
    }
    return (
      <div>
        <ReactTable
          defaultPageSize={10}
          filterable
          data={this.state.storedApi}
          defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
          defaultSorted={[
            {
                id:"createdDateTime",
                desc:true
            }
        ]}
          columns={[{
            Header: 'GUID',
            accessor: 'guid', // String-based value accessors!
            width: 300,
            Cell: Row => (<div> <u>
       api/StoredApi/{Row.value}
            </u>
             
            </div>)
          }, {
            Header: 'Description',
            accessor: 'description',
          }, {
            // Required because our accessor is not a string
            Header: 'Table',
            accessor: 'tableName', // Custom value accessors!
            width: 200
          }, {
            Header: 'Column', // Custom header components!
            accessor: 'columnName',
            width: 350
          },
          {
            Header: 'Related Table', // Custom header components!
            accessor: 'relatedTable',
            width: 200
          },
          {
            Header: 'Related Column', // Custom header components!
            accessor: 'relatedColumn',
            width: 350
          },
          {
            Header: 'Created Date',
            accessor: 'createdDateTime',
            width: 200
          },
          {
            Header: '',
            filterable: false,
            Cell: row => (
              <div>
                <Button variant="raised" onClick={() => this.setState({ dialogOpen: true, row: row })} className="ButtonDelete" color="primary" >Edit</Button>
                <Button variant="raised" onClick={() => this.setState({deleteOpen : true, row : row })} className="ButtonDelete" color="secondary" >Delete</Button>

              </div>
            ),
            width: 180
          }
          ]}
        />
        <Dialog
          open={this.state.dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Edit API"}
          </DialogTitle>
          <DialogContent>
            {this.state.dialogOpen ? <EditAPI row={this.state.row} changeState={this.changeState} /> : <div></div>}

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Okay
                    </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.deleteOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Delete API"}
          </DialogTitle>
          <DialogContent>
            Are your sure you want delete this API?
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteStoredAPI.bind(this)} color="primary">
              Delete
                    </Button>
          </DialogActions>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary">
              Cancel
                    </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withCookies, withRouter)(ManageAPI);
import React, { Component } from 'react';
import { FilePicker } from 'react-file-picker'
import { Button, TextField } from '@material-ui/core';
import '../UploadData/UploadData.css';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';

class UploadData extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            "File": [],
            "FileName": "",
            "Category": "",
            "redirectToReferrer": false
        });
        this.onChangeFilePicker = this.onChangeFilePicker.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.sendData = this.sendData.bind(this);
    }

    componentDidMount() {
        fetch('/api/User/CheckToken', {
            method: "GET",
            credentials: "same-origin"
        }).then(resp => resp.status).then(status => {
            if (status === 200)
                this.setState({ redirectToReferrer: false });
            else
                this.setState({ redirectToReferrer: true });
        });

    }

    onChangeFilePicker = File => {
        this.setState({ "File": File, "FileName": File["name"] });
    }

    onChangeText = event => {
        this.setState({ "Category": event.target.value });
    }

    sendData() {
        var formData = new FormData();
        formData.append("Category", this.state.Category);
        formData.append("File", this.state.File);
        fetch("/api/UploadData", {
            method: 'POST',
            credentials: 'same-origin',
            body: formData
        }).then(function (response) {
            if (response.status === 200) {
                alert("Upload Succeeded")
            }
        });
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/LoginPage' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div className="MainForm" >
                <div className="UploadFile" >
                    <TextField
                        value={this.state.Category}
                        label="Category"
                        margin="normal"
                        variant="outlined"
                        onChange={this.onChangeText}
                    />
                </div>

                <div className="Picker" >
                    <FilePicker
                        extensions={['xlsx', 'xls']}
                        onError={errMsg => alert(errMsg)}
                        onChange={this.onChangeFilePicker}
                    >
                        <div className="UploadFile" >
                            <Button variant="raised" className="MainForm" >
                                Select File
                    </Button>
                        </div>

                    </FilePicker>
                    <div className="UploadFile" >
                        {this.state.FileName}
                    </div>

                </div>
                <div className="UploadFile" >
                    <Button onClick={this.sendData} variant="raised" color="primary"  >
                        Upload File
                    </Button>
                </div>

            </div>
        );
    }
}

export default withRouter(UploadData);
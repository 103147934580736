import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, Button, InputLabel, FormControl, FormControlLabel, Switch, Input, Checkbox, ListItemText, Chip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import './DatabaseSelector.css';
import { compose } from 'recompose';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';


function Transition(props) {
    return <Slide direction="up" {...props} />;
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


class DatabaseSelector extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = ({
            selectValue: "Window",
            server: "",
            username: "",
            password: "",
            description: "",
            usePassword: false,
            db: [],
            dbSelected: "",
            dbIsLoaded: false,
            table: [],
            tables: [],
            tableSelected: "",
            tableIsLoaded: false,
            column: [],
            columnSelected: [],
            columnIsLoaded: false,
            tableRelated: [],
            tableRelatedSelected: "",
            tableRelatedIsLoaded: false,
            columnRelated: [],
            columnRelatedSelected: [],
            columnRelatedSelectedIsLoaded: false,
            dialogOpen: false,
            storedApi: "",
            dialogTitle: "",
            dialogContent: "",
            redirectToReferrer: false,
            columnSelectAll: false,
            columnRelatedSelectedAll: false,
            useStoredConn: false,
            connectionName: "",
            connectionSaved: false,
            connId: "",
            connection: [],
            connectionLoaded: false
        });
        this.GetDB = this.GetDB.bind(this);
        this.GetTable = this.GetTable.bind(this);
        this.GetColumn = this.GetColumn.bind(this);
        this.GenerateApi = this.GenerateApi.bind(this);
        this.GetRelatedTable = this.GetRelatedTable.bind(this);
        this.SaveConnection = this.SaveConnection.bind(this);
        this.GetSavedConnection = this.GetSavedConnection.bind(this);
    }
    componentDidMount() {
        fetch('/api/User/CheckToken', {
            method: "GET",
            credentials: "same-origin"
        }).then(resp => resp.status).then(status => {
            if (status === 200)
                this.setState({ redirectToReferrer: false });
            else
                this.setState({ redirectToReferrer: true });
        });

    }
    selectOnchange = name => event => {
        this.setState({ [name]: event.target.value });
        if (name == "tableSelected")
            this.GetColumn(event.target.value, false);
        if (name == "tableRelatedSelected")
            this.GetColumn(event.target.value, true);
        if (name == "connSelected"){
            this.GetTable(event.target.value);
            this.setState({connId : event.target.value, connectionSaved : true });            
        }           
    };
    textfieldOnChange = name => event => {
        this.setState({ [name]: event.target.value });
    };
    switchOnChange = event => {

        if (event.target.checked == true) {
            this.GetSavedConnection();
        }
        else {
            this.setState({ connectionLoaded: false })
        }
        this.setState({ useStoredConn: event.target.checked })
    };
    handleClose = () => {
        this.setState({ dialogOpen: false });
    };
    multipleOnChange = name => event => {
        if (name == "columnRelatedSelected") {
            if (event.target.value.indexOf("SELECT ALL") > -1) {
                this.setState({ [name]: this.state.columnRelated, columnRelatedSelectedAll: true })
            }
            else if (event.target.value.indexOf("SELECT NONE") > -1) {
                this.setState({ [name]: [], columnRelatedSelectedAll: false })
            }
            else
                this.setState({ [name]: event.target.value })
        }
        else {
            if (event.target.value.indexOf("SELECT ALL") > -1) {
                this.setState({ [name]: this.state.column, columnSelectAll: true })
            }
            else if (event.target.value.indexOf("SELECT NONE") > -1) {
                this.setState({ [name]: [], columnSelectAll: false })
            }
            else
                this.setState({ [name]: event.target.value })
        }



    };

    GetDB() {
        const { cookies } = this.props;
        fetch('/api/GenerateApi/GetDB', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                //'Authorization': "Bearer " + cookies.get("token"),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "servername": this.state.server,
                "authType": this.state.selectValue,
                "username": this.state.username,
                "password": this.state.password
            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => {
            if (data.length > 0)
                this.setState({ db: data, dbIsLoaded: true });
            else
                this.setState({ dialogOpen: true, dialogTitle: "Connection Not Found", dialogContent: data.message })
        });
    }

    SaveConnection() {
        fetch('api/ApiManagement/SaveStoredConn', {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "servername": this.state.server,
                "authType": this.state.selectValue,
                "username": this.state.username,
                "password": this.state.password,
                "database": this.state.dbSelected,
                "Name": this.state.connectionName

            })

        }).then(response => response.json()).then(data => {
            this.GetTable(data.id);
            this.setState({ connId: data.id, connectionSaved: true });
        })
    }

    GetSavedConnection() {
        fetch('/api/ApiManagement/GetStoredConn', {
            method: 'GET',
            credentials: 'same-origin'
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => this.setState({ connection: data, connectionLoaded: true }));
    }

    GetTable(Id) {
        fetch('/api/GenerateApi/GetTable', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": Id

            })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => this.setState({ table: data, tableIsLoaded: true }));
    }

    GetColumn(table, isRelatedTable) {
        fetch('/api/GenerateApi/GetColumn', {
            method: 'POST',
            credentials: 'same-origin',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": this.state.connId,
                "tableName": table,

            })

        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }
        }).then(data => {
            if (!isRelatedTable) {
                this.setState({ column: data, columnSelected: [], columnIsLoaded: true });
            }
            else {
                this.setState({ columnRelated: data, columnRelatedSelected: [], columnRelatedSelectedIsLoaded: true });
            }
        });
    }

    GetRelatedTable() {
        let table = this.state.tableSelected.substring(this.state.tableSelected.indexOf(".") + 1, this.state.tableSelected.indexOf("("));
        let schemaName = this.state.tableSelected.substring(0, this.state.tableSelected.indexOf("."));
        fetch("/api/generateapi/GetReferenceTable", {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "connStringGuid": this.state.connId,
                "tableName": table,
                "schemaName": schemaName

            })

        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data =>

            this.setState({ tableRelated: data, tableRelatedIsLoaded: true })
        );
    }

    GenerateApi() {
        const { cookies } = this.props;
        let columns = this.state.columnSelected.join(", ");
        let connString = "";
        let relCol = this.state.columnRelatedSelected.join(", ");
        if (this.state.selectValue == "Window")
            connString = "Server=" + this.state.server + ";Database=" + this.state.dbSelected + ";Trusted_Connection=True;"
        else
            connString = "Server=" + this.state.server + ";Initial Catalog=" + this.state.dbSelected + ";Persist Security Info=False;User ID=" + this.state.username + ";Password=" + this.state.password + ";MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=True;Connection Timeout=30;"

        fetch('/api/GenerateApi', {
            method: "POST",
            credentials: 'same-origin',
            headers: {
                //'Authorization': "Bearer " + cookies.get("token"),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "StoredConnString": this.state.connId,
                "TableName": this.state.tableSelected,
                "ColumnName": columns,
                "Description": this.state.description,
                "RelatedTable": this.state.tableRelatedSelected,
                "RelatedColumn": relCol
            })
        }).then(response => {
            if (response.status === 401) {
                cookies.remove("token", { path: '/' });
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }

        }).then(data => {
            let guid = data.guid;
            let endPoint = "/api/StoredApi/" + guid;
            this.setState({ storedApi: endPoint, dialogOpen: true, dialogTitle: "API is Generated", dialogContent: "Use this Endpoint to access your API " + endPoint });
        })
    }
    render() {
        const { from } = this.props.location.state || { from: { pathname: '/loginpage' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div className="BodyForm" >

                <div className="LeftSide" >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.useStoredConn}
                                onChange={this.switchOnChange}

                            />
                        }
                        label="Use Stored Connection"
                    />
                    {this.state.connectionLoaded ?
                        <FormControl className="ComponentDiv" margin="normal" >

                            <InputLabel htmlFor="conn-selected">Saved Connection</InputLabel>
                            <Select
                                value={this.state.connId}
                                onChange={this.selectOnchange('connSelected')}
                                inputProps={{
                                    name: 'conn',
                                    id: 'conn-selected',
                                }}
                            >
                                {this.state.connection.map(item => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> :
                         <div className="Body" >
                            <FormControl className="ComponentDiv" margin="normal" >

                                <InputLabel htmlFor="auth-type">Auth Type</InputLabel>
                                <Select
                                    value={this.state.selectValue}
                                    onChange={this.selectOnchange('selectValue')}
                                    inputProps={{
                                        name: 'auth',
                                        id: 'auth-type',
                                    }}
                                >
                                    <MenuItem value={"Window"}>Window Auth</MenuItem>
                                    <MenuItem value={"Sql"}>SQL Server Auth</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                className="ComponentDiv"
                                value={this.state.server}
                                onChange={this.textfieldOnChange("server")}
                                id="outlined-name"
                                label="Server Name"
                                margin="normal"
                                variant="outlined"
                            />
                            {this.state.usePassword || this.state.selectValue == "Sql" ? <div className="Body" >
                                <TextField
                                    className="ComponentDiv"
                                    value={this.state.username}
                                    onChange={this.textfieldOnChange("username")}
                                    label="Username"
                                    margin="normal"
                                    variant="outlined"
                                />


                                <TextField
                                    className="ComponentDiv"
                                    value={this.state.password}
                                    onChange={this.textfieldOnChange("password")}
                                    label="Password"
                                    margin="normal"
                                    variant="outlined"
                                    type="password"
                                />


                            </div> : <div></div>}

                            <Button className="ComponentDiv" variant="outlined" onClick={this.GetDB} > Connect </Button>

                            {this.state.dbIsLoaded ?
                                <div className="Body" >
                                    <FormControl className="ComponentDiv" margin="normal" >

                                        <InputLabel htmlFor="db-selected">Db</InputLabel>
                                        <Select

                                            value={this.state.dbSelected}
                                            onChange={this.selectOnchange('dbSelected')}
                                            inputProps={{
                                                name: 'db',
                                                id: 'db-selected',
                                            }}
                                        >
                                            {this.state.db.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        className="ComponentDiv"
                                        value={this.state.connectionName}
                                        onChange={this.textfieldOnChange("connectionName")}
                                        label="Connection Name"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <Button className="ComponentDiv" onClick={this.SaveConnection} variant="outlined" > Save Connection </Button>
                                </div>

                                : <div></div>}

                        </div>
                    }

                </div>
                {this.state.connectionSaved ? <div>
                    <div className="Body" >
                        {this.state.tableIsLoaded ? <FormControl margin="normal"  >
                            <InputLabel htmlFor="table-selected">Table</InputLabel>
                            <Select
                                className="TableSelect"
                                value={this.state.tableSelected}
                                onChange={this.selectOnchange('tableSelected')}
                                inputProps={{
                                    name: 'db',
                                    id: 'table-selected',
                                }}
                            >
                                {this.state.table.map(item => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                            : <div></div>}

                        {this.state.columnIsLoaded ?
                            <div className="Select" >
                                <FormControl margin="normal" >
                                    <InputLabel htmlFor="select-multiple-chip"> Columns</InputLabel>
                                    <Select
                                        MenuProps={MenuProps}
                                        multiple
                                        value={this.state.columnSelected}
                                        onChange={this.multipleOnChange("columnSelected")}
                                        input={<Input id="select-multiple-chip" />}
                                        renderValue={selected => (
                                            <div className="row">

                                                {selected.map(value => (
                                                    <div className="column" >
                                                        <Chip key={value} label={value} className="chip" />
                                                    </div>
                                                ))}


                                            </div>
                                        )}
                                    >
                                        <MenuItem key="SELECT ALL" value={this.state.columnSelectAll ? "SELECT NONE" : "SELECT ALL"} >
                                            <ListItemText primary={this.state.columnSelectAll ? "SELECT NONE" : "SELECT ALL"} />
                                        </MenuItem>


                                        {this.state.column.map(column => (
                                            <MenuItem key={column} value={column}>
                                                <Checkbox checked={this.state.columnSelected.indexOf(column) > -1} />
                                                <ListItemText primary={column} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>



                                {this.state.columnSelected.length > 0 ? <div className="Body" > <Button className="ComponentDiv" variant="outlined" onClick={this.GetRelatedTable} > Add Related Table </Button>
                                    {this.state.tableRelatedIsLoaded ? <div className="Body" > <FormControl margin="normal" >
                                        <InputLabel htmlFor="table-selected">Related Table</InputLabel>
                                        <Select
                                            className="ComponentDiv"
                                            value={this.state.tableRelatedSelected}
                                            onChange={this.selectOnchange('tableRelatedSelected')}
                                            inputProps={{
                                                name: 'db',
                                                id: 'tableRelated-selected',
                                            }}
                                        >
                                            {this.state.tableRelated.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        {this.state.columnRelatedSelectedIsLoaded ?
                                            <FormControl className="ComponentDiv" margin="normal" >
                                                <InputLabel htmlFor="select-multiple-checkbox">Related Column</InputLabel>
                                                <Select
                                                    multiple
                                                    value={this.state.columnRelatedSelected}
                                                    onChange={this.multipleOnChange("columnRelatedSelected")}
                                                    input={<Input id="select-multiple-checkbox" />}
                                                    renderValue={selected => (
                                                        <div className="row">

                                                            {selected.map(value => (
                                                                <div className="column" >
                                                                    <Chip key={value} label={value} className="chip" />
                                                                </div>
                                                            ))}


                                                        </div>
                                                    )}
                                                >
                                                    <MenuItem key="SELECT ALL" value={this.state.columnRelatedSelectedAll ? "SELECT NONE" : "SELECT ALL"} >
                                                        <ListItemText primary={this.state.columnRelatedSelectedAll ? "SELECT NONE" : "SELECT ALL"} />
                                                    </MenuItem>
                                                    {this.state.columnRelated.map(column => (<MenuItem key={column} value={column}>
                                                        <Checkbox checked={this.state.columnRelatedSelected.indexOf(column) > -1} />
                                                        <ListItemText primary={column} />
                                                    </MenuItem>))}
                                                </Select>
                                            </FormControl>
                                            : <div></div>}
                                    </div>
                                        : <div></div>}

                                </div>
                                    : <div> </div>}


                                <TextField
                                    className="ComponentDiv"
                                    multiline
                                    value={this.state.description}
                                    onChange={this.textfieldOnChange("description")}
                                    label="Description"
                                    margin="normal"
                                    variant="outlined"
                                />


                                <Button className="ComponentDiv" variant="outlined" onClick={this.GenerateApi} > Generate </Button>

                            </div>
                            : <div />}

                        <Dialog
                            open={this.state.dialogOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {this.state.dialogTitle}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    {this.state.dialogContent}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.handleClose} color="primary">
                                    Okay
            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div> : <div>
                    </div>}

            </div >
        );
    }
}
export default compose(withCookies, withRouter)(DatabaseSelector);
import 'date-fns';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { Button } from '@material-ui/core';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { compose } from 'recompose';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined && row[id] !== null ?
            String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase())
            :
            true
    );
}


const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {

        minWidth: 300,
        maxWidth: 400,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

class TransactionLog extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props);
        const { cookies } = props;

        this.state = ({
            logTrans: [],
            redirectToReferrer: false,
            date: Date.now(),
        });
        this.handleDateChange = this.handleDateChange.bind(this);
        this.refreshTable = this.refreshTable.bind(this);

    }

    handleDateChange = (date) => {
        var dateUnix = new Date(date).valueOf();
        this.setState({ date: dateUnix })
        this.refreshTable(dateUnix);
    }

    componentDidMount() {
        this.setState({ date: Date.now() })
        this.refreshTable();
    }
    refreshTable(dateUnix) {
        let dateTime;
        if (dateUnix != undefined || dateUnix != null) {
            dateTime = dateUnix;
        }
        else {
            dateTime = this.state.date;
        }
        fetch('/api/logtransaction', {
            method: "GET",
            credentials: 'same-origin',
            headers: new Headers({
                'timeStamp': dateTime,
            })
            // headers : new Headers({
            //     'Authorization' : "Bearer " + cookies.get("token")
            //   })
        }).then(response => {
            if (response.status === 401) {
                this.setState({ redirectToReferrer: true });
            }
            else {
                return response.json();
            }
        }).then(data => this.setState({ logTrans: data }));
    }

    render() {
        const { date } = this.state;
        const { from } = this.props.location.state || { from: { pathname: '/loginpage' } }
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        return (
            <div>
                <div>
                    <h5>
                        Filter Date :
                </h5>
                    <DatePicker
                        selected={date}

                        onChange={this.handleDateChange.bind(this)}

                    />
                </div>

                <ReactTable
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    defaultPageSize={10}
                    data={this.state.logTrans}
                    columns={[
                        {
                            Header: "Log ID",
                            accessor: "guid",
                            width: 340
                        },
                        {
                            Header: "API Guid",
                            accessor: "apiguid",
                            width: 340
                        },
                        {
                            Header: "Completed",
                            Cell: row => (
                                <div>
                                    {row.original.isFinish ? "Completed" : "Failed"}
                                </div>
                            )
                        },
                        {
                            Header: "Created By",
                            accessor: "email",

                        },
                        {
                            Header: "Table Name",
                            accessor: "tableName"
                        },
                        {
                            Header: "Description",
                            accessor: "description"
                        },
                        {
                            Header: "Total Record",
                            accessor: "totalRecord"
                        },
                        {
                            Header: "Record Inserted",
                            accessor: "totalRecordInserted"
                        },
                        {
                            Header: "Record Failed Inserted",
                            accessor: "totalRecordFailed"
                        },
                        {
                            Header: "Timestamp",
                            accessor: "timestamp",

                        },
                        {
                            Header: '',
                            filterable: false,
                            Cell: row => (
                                <div>
                                    <Button variant="raised" onClick={() => this.props.history.push({
                                        pathname: "/logDetails",
                                        state: { detail: row.original }
                                    })}
                                        className="ButtonDelete" color="primary" >Details</Button>

                                </div>
                            ),
                            width: 100
                        }
                    ]}
                    defaultSorted={[
                        {
                            id: "timestamp",
                            desc: true
                        }
                    ]}
                />
            </div>
        );
    }
}

export default compose(withRouter, withCookies)(TransactionLog);